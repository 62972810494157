import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./Grid.css";

const ItemType = "NAME";

// Draggable Name Component
const DraggableName = ({ name, index, moveName, isDragging }) => {
  const [{ isOver }, drag, preview] = useDrag(() => ({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveName(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  }));

  return (
    <div
      ref={(node) => drag(drop(preview(node)))}
      className={`draggable-name text-center border p-2 bg-light ${
        isDragging ? "dragging" : ""
      }`}
    >
      {name}
    </div>
  );
};

// Main Game Component
const MatchGame = () => {
  const images = [
    {
      id: 1,
      src: "https://via.placeholder.com/150?text=Apple",
      correctName: "Apple",
    },
    {
      id: 2,
      src: "https://via.placeholder.com/150?text=Banana",
      correctName: "Banana",
    },
    {
      id: 3,
      src: "https://via.placeholder.com/150?text=Cat",
      correctName: "Cat",
    },
  ];

  const [names, setNames] = useState(["Banana", "Cat", "Apple"]);

  const moveName = (fromIndex, toIndex) => {
    const updatedNames = [...names];
    const [movedName] = updatedNames.splice(fromIndex, 1);
    updatedNames.splice(toIndex, 0, movedName);
    setNames(updatedNames);
  };

  const checkAnswers = () => {
    const isCorrect = images.every(
      (image, index) => image.correctName === names[index]
    );
    alert(isCorrect ? "Correct!" : "Try again!");
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className="mt-5">
        <Row>
          {images.map((image, index) => (
            <Col key={image.id} sm={4} className="text-center mb-4">
              <Card className="kanban-card">
                <Card.Img variant="top" src={image.src} />
                <Card.Body>
                  <DraggableName
                    name={names[index]}
                    index={index}
                    moveName={moveName}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <button className="btn btn-primary mt-3" onClick={checkAnswers}>
          Submit
        </button>
      </Container>
    </DndProvider>
  );
};

export default MatchGame;
