import React, { useState, useRef, useEffect } from "react";
import "./Grid.css";

import { wordSearchData } from "data/courses/MyCoursesData";
import { X } from "react-feather";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBookTextAction } from "actions/books";

const WordSearchGrid = () => {
  const dispatch = useDispatch();
  const [ping, setPing] = useState(false);
  const [data, setData] = useState(wordSearchData);
  const { id } = useParams();
  const navigate = useNavigate();
  const { wordSearch } = useSelector((state) => state.books);

  useEffect(() => {
    if (id) {
      dispatch(getBookTextAction(id));
    }
  }, [dispatch, id, ping]);

  useEffect(() => {
    setData(wordSearch);
  }, [wordSearch]);

  const { question, gridSize, letters, hiddenWords } = data;
  const [startCell, setStartCell] = useState(null);
  const [currentCell, setCurrentCell] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [foundWords, setFoundWords] = useState([]); // Track found words
  const [currentWord, setCurrentWord] = useState(""); // Track the word being found
  const [currentDefinition, setCurrentDefinition] = useState(""); // Track the word's definition
  const [cellSize, setCellSize] = useState(70); // Default value, will be updated dynamically
  const canvasRef = useRef(null);

  useEffect(() => {
    const updateCellSize = () => {
      const gridContainer = document.querySelector(".grid-container");
      if (gridContainer) {
        const firstCell = gridContainer.querySelector(".grid-cell");
        if (firstCell) {
          const rect = firstCell.getBoundingClientRect();
          setCellSize(rect.width); // Use the actual width of the cell
        }
      }
    };

    // Update cell size on mount and window resize
    updateCellSize();
    window.addEventListener("resize", updateCellSize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", updateCellSize);
    };
  }, []);
  const lineColor = [
    "rgba(255, 255, 0, 0.6)", // Yellow
    "rgba(102, 179, 255, 0.6)", // Light Blue
    "rgba(168, 230, 161, 0.6)", // Green
    "rgba(255, 128, 171, 0.6)", // Pink
    "rgba(255, 184, 77, 0.6)", // Orange
    "rgba(194, 160, 255, 0.6)", // Purple
  ];

  const isGameComplete = hiddenWords?.length === foundWords?.length;
  const playAudio = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = "en-US";
    speech.rate = 1;
    speech.pitch = 1;
    window.speechSynthesis.speak(speech);
  };

  useEffect(() => {
    drawLines();
  }, [currentCell, drawing, foundWords]);

  const getLineCells = (start, end) => {
    const lineCells = [];
    const dx = end.y - start.y;
    const dy = end.x - start.x;
    const steps = Math.max(Math.abs(dx), Math.abs(dy));

    for (let i = 0; i <= steps; i++) {
      const x = Math.round(start.x + (i * dy) / steps);
      const y = Math.round(start.y + (i * dx) / steps);
      lineCells.push({ x, y });
    }

    return lineCells;
  };

  const handleMouseDown = (x, y) => {
    setStartCell({ x, y });
    setDrawing(true);
  };

  const handleMouseOver = (x, y) => {
    if (drawing && startCell) {
      const deltaX = x - startCell.x;
      const deltaY = y - startCell.y;
      const isDiagonal = Math.abs(deltaX) === Math.abs(deltaY);
      const isHorizontal = deltaX === 0;
      const isVertical = deltaY === 0;

      if (isDiagonal || isHorizontal || isVertical) {
        setCurrentCell({ x, y });
      }
    }
  };

  const handleMouseUp = async () => {
    if (startCell && currentCell) {
      const lineCells = getLineCells(startCell, currentCell);
      const word = lineCells
        ?.map((cell) =>
          letters?.[cell.x]?.[cell.y] !== undefined
            ? letters[cell.x][cell.y]
            : null
        )
        .filter((char) => char !== null)
        .join("");

      if (
        hiddenWords.includes(word) &&
        !foundWords.some((found) => found.word === word)
      ) {
        const updatedFoundWords = [...foundWords, { word, lineCells }];
        setFoundWords(updatedFoundWords);

        setCurrentWord(word);
        try {
          const result = await lookupWord(word);
          setCurrentDefinition(result.definition);
        } catch (error) {
          console.error("Error fetching word definition:", error);
        }
      }
    }

    setDrawing(false);
    setStartCell(null);
    setCurrentCell(null);
  };

  /// touch events

  const getCellFromTouch = (touch) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Swap x and y to align with the grid's coordinate system
    const y = Math.floor((touch.clientX - rect.left) / cellSize); // clientX corresponds to columns (y)
    const x = Math.floor((touch.clientY - rect.top) / cellSize); // clientY corresponds to rows (x)

    return { x, y };
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    const cell = getCellFromTouch(touch);
    setStartCell(cell);
    setDrawing(true);
  };

  const handleTouchMove = (e) => {
    if (drawing && startCell) {
      const touch = e.touches[0];
      const cell = getCellFromTouch(touch);
      const deltaX = cell.x - startCell.x;
      const deltaY = cell.y - startCell.y;
      const isDiagonal = Math.abs(deltaX) === Math.abs(deltaY);
      const isHorizontal = deltaX === 0;
      const isVertical = deltaY === 0;

      if (isDiagonal || isHorizontal || isVertical) {
        setCurrentCell(cell);
      }
    }
  };

  const handleTouchEnd = async () => {
    if (startCell && currentCell) {
      const lineCells = getLineCells(startCell, currentCell);
      const word = lineCells
        ?.map((cell) =>
          letters?.[cell.x]?.[cell.y] !== undefined
            ? letters[cell.x][cell.y]
            : null
        )
        .filter((char) => char !== null)
        .join("");

      if (
        hiddenWords.includes(word) &&
        !foundWords.some((found) => found.word === word)
      ) {
        const updatedFoundWords = [...foundWords, { word, lineCells }];
        setFoundWords(updatedFoundWords);

        setCurrentWord(word);
        try {
          const result = await lookupWord(word);
          setCurrentDefinition(result.definition);
        } catch (error) {
          console.error("Error fetching word definition:", error);
        }
      }
    }

    setDrawing(false);
    setStartCell(null);
    setCurrentCell(null);
  };

  const drawLines = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Clear previous drawings
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the temporary line (current drag)
      if (startCell && currentCell) {
        const currentLineCells = getLineCells(startCell, currentCell);
        const startX = startCell.y * cellSize + cellSize / 2;
        const startY = startCell.x * cellSize + cellSize / 2;
        const endX = currentCell.y * cellSize + cellSize / 2;
        const endY = currentCell.x * cellSize + cellSize / 2;

        ctx.strokeStyle = "rgba(128, 128, 128, 0.5)"; // Temporary line color
        ctx.lineWidth = 30;
        ctx.lineJoin = "round";
        ctx.lineCap = "round";

        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
      }

      // Draw finalized lines for found words
      foundWords.forEach(({ lineCells, word }, index) => {
        const startX = lineCells[0].y * cellSize + cellSize / 2;
        const startY = lineCells[0].x * cellSize + cellSize / 2;
        const endX =
          lineCells[lineCells.length - 1].y * cellSize + cellSize / 2;
        const endY =
          lineCells[lineCells.length - 1].x * cellSize + cellSize / 2;

        ctx.strokeStyle = lineColor[index % lineColor.length]; // Assign color from lineColor
        ctx.lineWidth = 30;
        ctx.lineJoin = "round";
        ctx.lineCap = "round";

        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
      });
    }
  };

  const handleNextPuzzle = () => {
    // Reset found words
    setFoundWords([]);

    // Clear the canvas
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    // Optionally: Reset the current word and definition
    setCurrentWord("");
    setCurrentDefinition("");

    // Trigger the next puzzle logic (e.g., reload the puzzle or fetch a new one)
    setPing(!ping);
  };

  return (
    <div className="g-container">
      <X
        className="go-back-button"
        size={cellSize - 10}
        onClick={() => navigate(`/student/games/${id}`)}
      />
      <h3 className=".title-h3">{question}</h3>

      <div
        className="grid-container"
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
        style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}
      >
        <canvas
          ref={canvasRef}
          className="canvas-overlay"
          width={gridSize * cellSize}
          height={gridSize * cellSize}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
        {Array.from({ length: gridSize })?.map((_, x) => (
          <div key={x} className="grid-row">
            {Array.from({ length: gridSize })?.map((_, y) => (
              <div
                key={`${x}-${y}`}
                className="grid-cell"
                onMouseDown={() => handleMouseDown(x, y)}
                onMouseOver={() => handleMouseOver(x, y)}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
              >
                {letters[x][y]}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="word-list">
        <h3>Hidden Words</h3>
        <ul>
          {hiddenWords?.map((word) => (
            <li
              key={word}
              className={
                foundWords.some((found) => found.word === word)
                  ? "crossed-out"
                  : ""
              }
            >
              {word}
            </li>
          ))}
        </ul>
      </div>

      {/* Discovered Word Section */}
      <div className="discovered-word-section">
        {currentWord && (
          <div className="discovered-word-content">
            <p className="discovered-word">
              <strong>Word:</strong> {currentWord}
            </p>
            <p className="discovered-definition">
              <strong>Definition:</strong> {currentDefinition || "Loading..."}
            </p>
            <button
              className="audio-button"
              onClick={() => playAudio(currentWord)}
              title="Play Pronunciation"
            >
              <i
                className="fa fa-volume-up"
                style={{ fontSize: "20px", color: "#6343D8" }}
              ></i>
            </button>
          </div>
        )}
      </div>

      {/* Success Message */}
      {isGameComplete && (
        <div className="success-message">
          <h3>🎉 Congratulations! You found all the words! 🎉</h3>
          <Button className="next-puzzle-button" onClick={handleNextPuzzle}>
            Next Puzzle
          </Button>
        </div>
      )}
    </div>
  );
};

async function lookupWord(word) {
  try {
    const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data.title) {
      throw new Error(`Error: ${data.message}`);
    }

    const definition = data[0].meanings[0].definitions[0].definition;
    return { definition };
  } catch (error) {
    throw new Error("Unable to fetch the word. Please try again later.");
  }
}

export default WordSearchGrid;
