// import node module libraries
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Image, Card, ListGroup, Badge } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookToResultCard = ({ item, extraclass, link }) => {
  const GridView = () => {
    const navigate = useNavigate();
    return (
      <Card
        className={`mb-4 card-hover ${extraclass}`}
        onClick={() => {
          if (item.approved) {
            navigate(
              "/instructor/dashboard/" +
                item.book_id +
                "?session_id=" +
                item.id,
              { state: { quizData: item } }
            );
          } else {
            toast.error("Item is not approved, cannot navigate.");
          }
        }}
      >
        <Image
          src={item.book_img}
          alt={item?.book_name}
          className="card-img-top rounded-top-md"
        />

        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2">
            <Link to={link} className="text-inherit">
              {item?.book_name}
            </Link>
          </h3>
          <h5 className="h6 mb-2 text-truncate-line-2">
            <i className="fas fa-user me-1"></i>
            author : {item?.author}
          </h5>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item
              as="li"
              bsPrefix="list-inline-item"
              className="d-inline"
            >
              <i className="fas fa-clipboard me-1"></i>
              Session :{item?.session_name}
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              bsPrefix="list-inline-item"
              className="d-inline"
            >
              <Badge bg={`${item?.approved ? "success" : "warning"}`}>
                {item?.approved ? "Approved" : "Pending"}
              </Badge>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>

        <Card.Footer>
          {item?.passed ? (
            <div className="d-flex justify-content-between border-bottom py-2">
              <span>My Score: </span>
              {item?.score}/{item?.max_score}
            </div>
          ) : (
            <div
              className="d-flex justify-content-between border-bottom py-2 text-center"
              style={{ marginBottom: "11%" }}
            >
              <span>Awaiting your quiz completion.</span>
            </div>
          )}
          {item?.passed && (
            <div className="d-flex justify-content-between pt-2">
              <span>Percentage:</span>
              <span className="text-dark">{item?.percentage} % </span>
            </div>
          )}
        </Card.Footer>
      </Card>
      // </Link>
    );
  };

  return (
    <Fragment>
      <GridView />
      <ToastContainer />
    </Fragment>
  );
};

// Specifies the default values for props
BookToResultCard.defaultProps = {
  free: false,
  viewby: "grid",
  showprogressbar: false,
  extraclass: "",
  link: "#",
};

// Typechecking With PropTypes
BookToResultCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
  link: PropTypes.string,
};

export default BookToResultCard;
