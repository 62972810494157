// import node module libraries
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

// import data files
import QuizListData from "data/marketing/quiz/QuizListData";
import ReactCourse from "assets/images/course/quiz.png";
// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";
import BookLayout from "./BookLayout";
import { useSelector } from "react-redux";
import { getTimeValue } from "helper/utils";
//translate component
import { useTranslation } from "react-i18next";

const Quiz = () => {
  const { t } = useTranslation();
  const { quizs, results } = useSelector((state) => state.quizs);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionID = searchParams.get("session_id");
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <BookLayout>
      <Card className="mb-4">
        {/* Card header */}
        <Card.Header className="d-flex align-items-center justify-content-between">
          <div className="">
            <h3 className="mb-0">{t("quiz")} </h3>
          </div>
          {/* <div>
            <Link to="#" className="btn btn-primary btn-sm">
              Add New Quiz
            </Link>
          </div> */}
        </Card.Header>
        {/* Card body */}
        <Card.Body className="p-0">
          <Table responsive className="table-centered">
            <tbody>
              {results?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        {/* quiz img */}
                        <Link to="#">
                          {" "}
                          <img
                            src={ReactCourse}
                            alt=""
                            className="rounded img-4by3-lg"
                          />
                        </Link>
                        {/* quiz content */}
                        <div className="ms-3">
                          <h4 className="mb-2">
                            <Link to="#" className="text-inherit">
                              {item?.title}
                            </Link>
                          </h4>
                          <div>
                            <span>
                              <span className="me-2 align-middle">
                                <i className="fe fe-list"></i>
                              </span>
                              {getTimeValue(new Date(item?.release_date))}
                            </span>
                            <span className="ms-2">
                              <span className="me-2 align-middle">
                                <i className="fe fe-clock"></i>
                              </span>
                              {item?.duration}
                            </span>
                            <span className="ms-2">
                              <span className="me-2 align-middle">
                                <i className="fe fe-clock"></i>
                              </span>
                              {t("release_date")}{" "}
                              {getTimeValue(new Date(item?.release_date))}
                            </span>
                            {item.final_results?.completed === true && (
                              <Link
                                to={`/quiz/quiz-finish?book_id=${id}&session_id=${sessionID}&quiz_id=${item._id}&user_id=${item?.final_results?.user}`}
                                className="ms-2 text-body"
                              >
                                <span
                                  className="me-2 align-middle"
                                  style={{ color: "#FBBC04" }}
                                >
                                  <i className="fe fe-file-text"></i>
                                </span>
                                <span style={{ color: "#FBBC04" }}>
                                  {t(" result")}
                                </span>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {/* icon */}
                      <div>
                        {item?.final_results == "incomplete" ? (
                          (<Button
                            type="button"
                            variant="primary"
                            size="sm"
                            disabled={new Date(item.release_date) > new Date()}
                            onClick={() =>
                              navigate(
                                `/quiz/quiz_start?book_id=${id}&&session_id=${sessionID}&&quiz_id=${item._id}`
                              )
                            }
                          >
                            {t("start_quiz")}
                          </Button> /* : item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */ /*: item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */ /*: item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */ /*: item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */ /*: item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */ /*: item.final_results == "incorrected" ? (
                          <Button type="button" variant="warning" size="sm">
                            {t(" pending_pack")}
                          </Button>
                        )  */)
                        ) : item.final_results?.completed === false ? (
                          <Button
                            type="button"
                            variant="info"
                            size="sm"
                            onClick={() =>
                              navigate(
                                `/quiz/quiz_pass?session_id=${sessionID}&book_id=${id}&quiz_id=${item._id}&user_id=${item?.final_results?.user}`
                              )
                            }
                          >
                            {t("continue_quiz")}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            variant="success"
                            size="sm"
                            disabled={true}
                          >
                            {t("finished")}
                          </Button>
                        )}

                        {/* <Link to="#" className="ms-2 link-danger">
                          <i className="fe fe-trash-2"></i>
                        </Link> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <ToastContainer />
    </BookLayout>
  );
};

export default Quiz;
