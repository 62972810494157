// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Container,
  ListGroup,
  Accordion,
  useAccordionButton,
  Spinner,
  Modal,
} from "react-bootstrap";

// import custom components
import BlogArticlesList from "data/blog/blogArticlesData";
// import profile layout wrapper
import ProfileDashboard from "./ProfileDashboard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchAllResultsFromOneReaderAction,
  fetchUserDetailsAction,
} from "actions/quizs";
import SectionHeadingCenter from "../common/section-headings/SectionHeadingCenter";
import CourseDescriptionCard from "../landings/course-lead/CourseDescriptionCard";
import { CourseDescriptionList } from "data/marketing/LeadCourseData";
import CourseDescSettingCard from "../landings/course-lead/CourseDescSettingCard";
import HeroGradientHeader from "../iread-help-center/help-center/HeroGradientHeader";
import BlogCard from "../blog/BlogCard";
import {
  createAccountAction,
  getAccountsAction,
  selectAccountAction,
} from "actions/auth";
import { useTranslation } from "react-i18next";
import { cefrData } from "data/cefr";
import CefrCard from "../common/cards/CefrCard";
import { Avatar as Avatar1 } from "components/elements/bootstrap/Avatar";
import Avatar from "boring-avatars";
import schoolImage from "assets/images/png/school.png";
import { ToastContainer } from "react-toastify";
const ReaderDashboard = () => {
  const defaultImg =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png";
  const { t } = useTranslation();
  const subtitle = "account_setup_reminder";
  const dispatch = useDispatch();
  const school_id = localStorage.getItem("school_id");
  const { user, loading, accounts, is_authenticated } = useSelector(
    (state) => state.auth
  );
  const user_id = user?.quiz_id;
  // const user_id = "6597d979f32d372eec374e6b";
  useEffect(() => {
    dispatch(fetchUserDetailsAction(user_id));
    dispatch(fetchAllResultsFromOneReaderAction(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (user) {
      dispatch(getAccountsAction());
    }
  }, [dispatch, user]);

  const [newAccount, setNewAccont] = useState({
    username: "",
    password: "",
  });
  const [showSchools, setShowSchools] = useState([false]);
  const handleCreateAccount = (e) => {
    e.preventDefault();
    dispatch(createAccountAction(newAccount, setNewAccont));
  };
  const handleCloseSchools = () => {
    localStorage.setItem("school_id", user.schools[0]?.id);
    window.location.reload();
  };
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    return (
      <Fragment>
        <Link
          to="#"
          onClick={decoratedOnClick}
          className="d-flex align-items-center text-inherit text-decoration-none  mb-0"
          data-bs-toggle="collapse"
          style={{ marginTop: "3%" }}
          aria-controls={`collapse-${eventKey}`} // Use unique IDs for aria-controls
        >
          <Col style={{ position: "relative" }}>
            <Card
              style={{
                padding: "3% 0",
                borderLeft: `10px solid ${children.color}`,
                borderRadius: "8px  8px 0 0",
              }}
            >
              <Card.Body>
                <div className="mb-3 mb-lg-0">
                  <h3
                    className="mb-0 text-uppercase"
                    style={{ color: children.color }}
                  >
                    {children.title}
                  </h3>
                  <p className="mb-0">{children.desc}</p>
                </div>
              </Card.Body>
            </Card>
            <span
              className="chevron-arrow ms-5 "
              style={{ position: "absolute", right: "50%", bottom: 5 }}
            >
              <i
                className="fe fe-chevron-down "
                style={{ fontSize: "25px ", color: `${children.color}` }}
              ></i>
            </span>
          </Col>
        </Link>
      </Fragment>
    );
  };
  // accounts

  const handleSlecetAccount = (formdata) => {
    dispatch(selectAccountAction(formdata));
  };
  const handleCloseAccount = () => localStorage.setItem("account", user);
  const account = localStorage.getItem("account");

  useEffect(() => {
    if (
      !user?.schools?.map((e) => e.id).includes(Number(school_id)) &&
      is_authenticated &&
      user?.schools?.length > 1 &&
      account
    ) {
      setShowSchools(true);
    } else {
      setShowSchools(false);
    }
  }, [user, school_id]);

  return (
    <ProfileDashboard>
      <Modal
        show={!account && is_authenticated && accounts?.length > 1}
        onHide={handleCloseAccount}
        size="md"
      >
        <Modal.Body>
          <Table className="text-nowrap">
            <tbody>
              {accounts?.map((account, index) => (
                <tr
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  key={index}
                  onClick={() =>
                    handleSlecetAccount({
                      username: account.username,
                      email: account.email,
                    })
                  }
                >
                  <td>
                    <Avatar1
                      size="md"
                      type="image"
                      src={account.img}
                      className="rounded-circle"
                      alt="G K"
                    />
                  </td>
                  <th scope="row">{account.username}</th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal onHide={handleCloseSchools} size="md" show={showSchools}>
        <Modal.Body>
          <Table className="text-nowrap">
            <tbody>
              {user?.schools?.map((school, index) => (
                <tr
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  key={index}
                  onClick={() => {
                    localStorage.setItem("school_id", school.id);
                    window.location.reload();
                  }}
                >
                  <td>
                    <Avatar
                      size="md"
                      type="image"
                      src={schoolImage}
                      className="rounded-circle"
                      alt="G K"
                    />
                  </td>
                  <th scope="row">{school.name}</th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Card className="my-4">
        {/* <Card.Header style={{ borderLeft: "10px solid #0EA5E9" ,borderRadius: "0 0 0 8px"}}>
				
				</Card.Header> */}
        <Card.Body
          style={{ borderLeft: "10px solid #0EA5E9", borderRadius: "8px" }}
        >
          <Row>
            <SectionHeadingCenter color={"#0EA5E9"} subtitle={subtitle} />

            <Col lg={6} sm={12}>
              <CourseDescriptionCard item={CourseDescriptionList[0]} />
            </Col>
            <Col lg={6} sm={12}>
              <CourseDescSettingCard item={CourseDescriptionList[1]} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Accordion style={{ marginBottom: "3%" }}>
        <ContextAwareToggle eventKey="3">
          {{
            title: t("accounts"),
            desc: t("accounts_managment"),
            color: "#F8C709",
          }}
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="3">
          <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
            <Card.Body
              style={{
                borderLeft: "10px solid #F8C709",
                borderRadius: "0 0 0 8px",
              }}
            >
              <Table className="text-nowrap">
                <tbody>
                  {accounts?.map((account, index) => (
                    <tr
                      onClick={() =>
                        handleSlecetAccount({
                          username: account.username,
                          email: account.email,
                        })
                      }
                      style={{ cursor: "pointer", marginTop: "15px" }}
                      key={index}
                    >
                      <td>
                        {account?.img == defaultImg ? (
                          <Avatar
                            size={40}
                            name={account?.username}
                            variant="beam"
                            colors={[
                              "#92A1C6",
                              "#146A7C",
                              "#F0AB3D",
                              "#C271B4",
                              "#C20D90",
                            ]}
                          />
                        ) : (
                          <Avatar1
                            size="md"
                            type="image"
                            src={account?.img}
                            className="rounded-circle"
                            alt="G K"
                          />
                        )}
                      </td>
                      <th scope="row">{account?.username}</th>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {accounts?.length < 3 && (
                <form>
                  <div className="mb-3">
                    {/* User name */}
                    <label htmlFor="username">{t("user_name")}</label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      placeholder={t("user_name")}
                      required
                      onChange={(e) =>
                        setNewAccont({
                          ...newAccount,
                          username: e.target.value,
                        })
                      }
                      value={newAccount.username}
                    />
                  </div>

                  <div className="mb-3">
                    {/* Password */}
                    <label htmlFor="password">{t("password")}</label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder={t("password")}
                      required
                      onChange={(e) =>
                        setNewAccont({
                          ...newAccount,
                          password: e.target.value,
                        })
                      }
                      value={newAccount.password}
                    />
                  </div>

                  {/* Button */}
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => handleCreateAccount(e)}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          className="me-2"
                          size="sm"
                        />
                      ) : (
                        t("add_account")
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Card.Body>
          </Card>
        </Accordion.Collapse>
        <ContextAwareToggle eventKey="4">
          {{ title: t("cefr"), desc: t("cefr_desc"), color: "#2D851D" }}
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="4">
          <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
            <Card.Body
              style={{
                borderLeft: "10px solid #2D851D",
                borderRadius: "0 0 0 8px",
              }}
            >
              <Row>
                {cefrData.map((item, index) => (
                  <Col key={index} md={6} sm={12}>
                    <CefrCard item={item} />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>

      <main
        style={{
          borderLeft: "10px solid #ff9720",
          borderRadius: "8px",
          marginBottom: "3%",
        }}
      >
        {/*  Page header  */}
        <section className="pt-3 pb-9 bg-white" style={{ paddingLeft: "2%" }}>
          <div>
            <SectionHeadingCenter
              color={"#ff9720"}
              subtitle={"dive_into_the"}
            />
          </div>
        </section>
        {/*  Content */}
        <section className="pb-2 bg-white">
          <Container>
            <Row>
              <Col lg={8} md={7} sm={12}>
                <Row>
                  {BlogArticlesList.slice(0, 2).map((item, index) => (
                    <Col lg={6} md={12} sm={12} key={index} className="d-flex">
                      <BlogCard item={item} />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4} md={5} sm={!2} className="mt-5 mt-md-0">
                {/*  card */}
                <Card className="mb-2 border ">
                  {/*  card body */}
                  <Card.Body className="p-4">
                    <h3>{t("recent_posts")} </h3>
                    <div className="mt-3">
                      <ListGroup
                        as="ul"
                        className=" mb-0"
                        bsPrefix="list-unstyled"
                      >
                        {BlogArticlesList.slice(0, 2).map((item, index) => (
                          <ListGroup.Item
                            as="li"
                            className="mb-3"
                            bsPrefix=" "
                            key={index}
                          >
                            <h4 className="lh-lg">
                              <Link
                                to={`/blog/article-single/${item.id}`}
                                className="text-inherit"
                              >
                                {t(item.title)}
                              </Link>
                            </h4>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>

      <Card
        className="my-1"
        style={{
          borderLeft: "10px solid #754FFE",
          borderRadius: "8px",
          marginBottom: "2%",
        }}
      >
        <Card.Header>
          <HeroGradientHeader />
        </Card.Header>
      </Card>
      <ToastContainer />
      {/* end of Page Content section*/}
    </ProfileDashboard>
  );
};
export default ReaderDashboard;
