// import node module libraries
import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

// import data files

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createQuizAction } from "actions/quizs";
import { getRandomIds } from "helper/utils";

const Training = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { book } = useSelector((state) => state.books);
  const { storyQuestions } = useSelector((state) => state.quizs);
  const selectedQuestions = getRandomIds(storyQuestions, 7);
  const myQuiz = {
    title: book.title + "training",
    pass_mark: 0,
    duration: 30,
    questions: selectedQuestions,
    withAnswers: true,
    training: true,
  };
  const handleGenerateQuiz = () => {
    dispatch(createQuizAction(myQuiz, navigate, id));
  };
  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Training</h3>
            <p className="mb-0">
              Test your knowledge and sharpen your skills with our Quiz Training
              section. Interactive and engaging quizzes designed to help you
              learn and grow in a fun way!
            </p>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {/* Table */}
          <div className="border-0 d-flex justify-content-center p-10">
            <Button onClick={handleGenerateQuiz}>Start</Button>
          </div>
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};

export default Training;
