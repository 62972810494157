import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { AccountSettingsMenu } from "routes/marketing/StudentDashboardMenu";
import ProfileCover from "components/marketing/common/headers/ProfileCover";
import Avatar3 from "assets/images/avatar/avatar-3.jpg";
import { useDispatch } from "react-redux";
import { logOutAction } from "actions/auth";
import { useSelector } from "react-redux";
import { fetchOneBook } from "actions/books";
import { getQuestionByCatAction } from "actions/quizs";
import { ToastContainer } from "react-toastify";

const ProfileLayout = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const pack_id = searchParams.get("pack_id");
  const { id } = useParams();

  const dashboardData = {
    avatar: Avatar3,
    name: "Stella Flores",
    username: "@stellaflores",
    linkname: "Account Settings",
    link: "/student/student-edit-profile",
    verified: true,
    outlinebutton: false,
    level: "",
  };
  const { book } = useSelector((state) => state.books);

  useEffect(() => {
    dispatch(getQuestionByCatAction({ category: book.title }));
  }, [book.id]);

  useEffect(() => {
    if (id) dispatch(fetchOneBook({ id, pack_id: Number(pack_id) }));
  }, [dispatch, id]);
  const handleLogOut = (id) => {
    if (id === 6) {
      dispatch(logOutAction());
    }
  };

  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />

          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={3} md={4} sm={12}>
              <Navbar
                expand="lg"
                className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav"
              >
                <Link
                  className="d-xl-none d-lg-none d-md-none text-inherit fw-bold fs-5 float-start py-1"
                  to="#"
                ></Link>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="p-0 focus-none border-0"
                  label="Responsive Menu"
                >
                  <span
                    className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary p-0 text-white float-end"
                    data-bs-toggle="collapse"
                    data-bs-target="#sidenav"
                    aria-controls="sidenav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fe fe-menu"></span>
                  </span>
                </Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto flex-column" as="ul">
                    <Nav.Item className="navbar-header" as="li">
                      {book?.title}
                    </Nav.Item>
                    {AccountSettingsMenu.map((item, index) => (
                      <Nav.Item
                        onClick={() => handleLogOut(item.id)}
                        as="li"
                        key={index}
                        className={`${
                          item.link === location.pathname ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link"
                          to={item.link + id + "?pack_id=" + pack_id}
                        >
                          <i className={`fas fa-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))}
                    <Nav.Item as="li">
                      <Link className="nav-link" to="/student/dashboard">
                        <i className="fas fa-star nav-icon"></i>
                        Subscriptions
                      </Link>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>

            <Col lg={9} md={8} sm={12}>
              {props.children}
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default ProfileLayout;
