export const AUTH = "AUTH";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const GET_READER_DASHBOARD = "GET_READER_DASHBOARD";
export const GET_FOLLOWED_PACKS = "GET_FOLLOWED_PACKS";
export const GET_UNFOLLOWED_BOOKS = "GET_UNFOLLOWED_BOOKS";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_IMAGE = "CHANGE_IMAGE";
export const FOLLOW_PACK = "FOLLOW_PACK";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";

//packs constants
export const GET_ALL_PAKCS = "GET_ALL_PAKCS";
export const GET_PACK_DETAILS = "GET_PACK_DETAILS";
export const GET_BOOKS_IN_PACK = " GET_BOOKS_IN_PACK";
export const GET_ONE_BOOK = "GET_ONE_BOOK";
export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const SELECT_PACK = "SELECT_PACK";
export const CLEAR_SELECTED = "CLEAR_SELECTED";

//Quizs constants
export const GET_ALL_QUIZS = "GET_ALL_QUIZS";
export const START_QUIZ = "START_QUIZ";
export const GET_QUIZ = "GET_QUIZ";
export const GET_FIRST_QUESTION = "GET_FIRST_QUESTION";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const GET_RESULT = "GET_RESULT";
export const GET_QUIZS_IN_SESSION = "GET_QUIZS_IN_SESSION";
export const GET_RESULT_IN_SESSION = "GET_RESULT_IN_SESSION";
export const GET_QUIZ_BY_TOKEN = "GET_QUIZ_BY_TOKEN";
export const GET_ALL_RESULT_FOR_ONE_READER = "GET_ALL_RESULT_FOR_ONE_READER";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_READER_RESULT = "GET_READER_RESULT";
export const GET_ANSWER = "GET_ANSWER";
export const RESET_ANSWER = "RESET_ANSWER";
export const QUESTION_ANSWER = "QUESTION_ANSWER";
export const QUESTION = "QUESTION";
//Session
export const GET_SESSION = "GET_SESSION";

//about book constants
export const GET_ABOUT_BOOK = "GET_ABOUT_BOOK";

//notification
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

export const GET_ALL_GUIDE = "GET_ALL_GUIDE";

//invoicing
export const GET_INVOICE_BY_CLIENT = "GET_INVOICE_BY_CLIENT";
export const GET_ONE_INVOICE = "GET_ONE_INVOICE";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const GET_INVOICE_NUMBER = "GET_INVOICE_NUMBER";

//shcools
export const CREATE_SHCOOL = "CREATE_SHCOOL";
export const UPDATE_SHCOOL = "UPDATE_SHCOOL";
export const DELETE_SHCOOL = "DELETE_SHCOOL";
export const GET_ALL_SHCOOLS = "GET_ALL_SHCOOLS";
export const GET_ONE_SHCOOL = "GET_ONE_SHCOOL";
// Words
export const FETCH_WORDS = "FETCH_WORDS";
