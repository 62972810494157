// Import node module libraries
import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Card, Row, Col, ListGroup, Badge } from "react-bootstrap";

// Import custom components
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

const BookCard = ({ item, viewby, link }) => {
  /** Used in Course Filter Page (ListView) */
  const ListView = () => (
    <Card className="mb-4 card-hover cursor-pointer">
      <Row className="g-0">
        {/* Left image section */}
        <Link
          to={link}
          className="bg-cover img-left-rounded col-12 col-md-12 col-xl-3 col-lg-3"
          style={{
            background: `url(${item.img}) no-repeat center center / cover`,
          }}
        >
          <Image
            src={item.img}
            alt={item.title}
            className="img-fluid d-lg-none invisible"
          />
        </Link>
        {/* Right content section */}
        <Col lg={9} md={12} sm={12}>
          <Card.Body>
            <h3 className="mb-2 text-truncate-line-2">
              <Link to={link} className="text-inherit">
                {item.title}
              </Link>
            </h3>
            <ListGroup as="ul" bsPrefix="list-inline" className="mb-5">
              <ListGroup.Item as="li" bsPrefix="list-inline-item">
                <i className="far fa-file me-1"></i>
                <span>Pages: </span>
                {item.page_number}
              </ListGroup.Item>
              <ListGroup.Item as="li" bsPrefix="list-inline-item">
                <i className="far fa-folder me-1"></i>
                <span>Category: </span>
                {item.category}
              </ListGroup.Item>
            </ListGroup>
            <Row className="align-items-center g-0">
              <Col>
                <span className="text-muted">Author: </span>
                {item.author}
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );

  /** Used in Instructor Profile Page (ListGroupView) */
  const ListGroupView = () => (
    <div className="d-lg-flex align-items-center">
      {/* Book image */}
      <div>
        <Image
          src={item.img}
          alt={item.title}
          className="rounded img-4by3-lg"
        />
      </div>
      {/* Book details */}
      <div className="ms-lg-3 mt-2 mt-lg-0">
        <h4 className="text-primary-hover">
          {item.title}{" "}
          <Badge bg="light-success" className="text-success">
            New
          </Badge>
        </h4>
        <ListGroup
          as="ul"
          bsPrefix="list-inline"
          className="fs-6 mb-0 text-inherit"
        >
          <ListGroup.Item as="li" bsPrefix="list-inline-item">
            <i className="far fa-clock me-1"></i>
            Release Date: {new Date(item.release_date).toLocaleDateString()}
          </ListGroup.Item>
          <ListGroup.Item as="li" bsPrefix="list-inline-item">
            <LevelIcon level={item.level || "--"} />
            Level: {item.category || "--"}
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );

  // Render based on `viewby` prop
  return (
    <Fragment>{viewby === "list" ? <ListView /> : <ListGroupView />}</Fragment>
  );
};

// Specifies the default values for props
BookCard.defaultProps = {
  viewby: "grid",
  link: "#",
};

// Typechecking With PropTypes
BookCard.propTypes = {
  item: PropTypes.shape({
    author: PropTypes.string,
    category: PropTypes.string,

    id: PropTypes.number,
    img: PropTypes.string,
    page_number: PropTypes.number,
    release_date: PropTypes.string,
    title: PropTypes.string,
    level: PropTypes.string, // Optional, default to "N/A" if not provided
  }).isRequired,
  viewby: PropTypes.string,
  link: PropTypes.string,
};

export default BookCard;
