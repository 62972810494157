import {
  FETCH_WORDS,
  GET_ABOUT_BOOK,
  GET_ALL_GUIDE,
  GET_ONE_BOOK,
} from "../actions/constants";

const booksReducer = (
  state = { book: {}, about_book: null, guides: [], wordSearch: {} },
  action
) => {
  switch (action.type) {
    case GET_ONE_BOOK:
      return { ...state, book: action?.payload };
    case GET_ABOUT_BOOK:
      return { ...state, about_book: action.payload };
    case GET_ALL_GUIDE:
      return { ...state, guides: action?.payload };
    case FETCH_WORDS:
      return { ...state, wordSearch: action.payload };

    default:
      return state;
  }
};

export default booksReducer;
