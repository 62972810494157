import React, { useEffect, useState } from "react";

const FillTheBlanks = ({
  item,
  setSelectedAnswer,
  selectedAnswer,
  disabled,
}) => {
  const [initialWords, setInitialWords] = useState([]);

  useEffect(() => {
    // Shuffle function to randomize words
    const shuffleArray = (array) => {
      return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    };

    // Reset initialWords and selectedAnswer when item changes
    setInitialWords(shuffleArray(item.data.blanks));
    const initialAnswers = Array(item.data.blanks.length).fill("");
    setSelectedAnswer(initialAnswers);
  }, [item, setSelectedAnswer]);

  // Handle when a word is clicked from the word list
  const handleChoiceClick = (word) => {
    if (disabled || !selectedAnswer || !Array.isArray(selectedAnswer)) return; // Ensure interaction is enabled

    // Place the selected word into the first available blank in the sentence
    const firstEmptyIndex = selectedAnswer.findIndex((ans) => ans === ""); // Find first empty slot
    if (firstEmptyIndex !== -1) {
      const updatedAnswers = [...selectedAnswer];
      updatedAnswers[firstEmptyIndex] = word;
      setSelectedAnswer(updatedAnswers);

      // Remove the word from the list
      setInitialWords(initialWords.filter((w) => w !== word));
    }
  };

  // Handle when a word in the sentence is clicked
  const handleWordInSentenceClick = (word, index) => {
    if (disabled || !selectedAnswer || !Array.isArray(selectedAnswer)) return; // Ensure interaction is enabled

    // Remove the word from the sentence and place it back to the initial words list
    const updatedAnswers = [...selectedAnswer];
    updatedAnswers[index] = ""; // Clear the selected answer
    setSelectedAnswer(updatedAnswers);

    // Add the word back to the initial words list
    if (word != "") {
      setInitialWords([...initialWords, word]);
    }
  };

  return (
    <div>
      {/* Sentence with words inserted */}
      <div style={{ marginBottom: "20px" }}>
        <p>
          {item.data.text.split("###").map((part, index) => (
            <span key={index}>
              {part}
              {index < item.data.blanks.length && (
                // Display words that are inserted into the blanks as clickable
                <span
                  onClick={() =>
                    handleWordInSentenceClick(
                      selectedAnswer?.[index] || "",
                      index
                    )
                  } // Word in the sentence
                  style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    backgroundColor: disabled ? "#f8d7da" : "#d1e7dd",
                    padding: "5px 10px",
                    margin: "5px",
                    borderRadius: "5px",
                    display: "inline-block",
                  }}
                >
                  {selectedAnswer?.[index] || "__________"}
                </span>
              )}
            </span>
          ))}
        </p>
      </div>

      {/* Word List */}
      <div style={{ marginBottom: "20px" }}>
        <h4>Choose words to fill the blanks</h4>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {initialWords.map((word, index) => (
            <div
              key={index}
              onClick={() => handleChoiceClick(word)} // Add word to sentence
              style={{
                cursor: disabled ? "not-allowed" : "pointer",
                backgroundColor: "lightgray",
                padding: "8px 15px",
                margin: "5px",
                borderRadius: "5px",
                opacity: disabled
                  ? 0.5
                  : selectedAnswer?.includes(word)
                  ? 0.5
                  : 1, // Fade out selected words
              }}
            >
              {word}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FillTheBlanks;
