import { getAboutBook, getBook, getBookText, getOneBook } from "api";
import {
  FETCH_WORDS,
  GET_ABOUT_BOOK,
  GET_ONE_BOOK,
  LOADING,
} from "./constants";
import {
  cleanTextAndFilterAuxiliaryWords,
  generateWordSearch,
} from "helper/utils";

export const fetchOneBook = (book) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getOneBook(book);

    dispatch({ type: GET_ONE_BOOK, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const fetchBook = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getBook(id);
    dispatch({ type: GET_ONE_BOOK, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchUnfollowedSessions = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getOneBook(id);
    dispatch({ type: GET_ONE_BOOK, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const getAboutBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAboutBook(id);

    dispatch({ type: GET_ABOUT_BOOK, payload: data.about });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ABOUT_BOOK, payload: null });
    dispatch({ type: LOADING, payload: false });
  }
};
export const getBookTextAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getBookText(id);

    const words = cleanTextAndFilterAuxiliaryWords(data.text);
    const directionsInput = [
      "right",
      "down",
      "diagonalDownRight",
      "diagonalUpRight",
    ];

    const wordSearch = generateWordSearch(words.randomWords, directionsInput);
    dispatch({ type: FETCH_WORDS, payload: wordSearch });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: FETCH_WORDS, payload: {} });
    dispatch({ type: LOADING, payload: false });
  }
};
