// import node module libraries
import { Row, Col, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components

// import media files
import CheckedMark from "assets/images/svg/checked-mark.svg";
import ProfileBackground from "assets/images/background/profile-bg.jpg";
import { useSelector } from "react-redux";
import Avatar from "boring-avatars";
const ProfileCover = ({ dashboardData }) => {
  const defaultImg =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png";
  const { user } = useSelector((state) => state.auth);
  return (
    <Row className="align-items-center">
      <Col xl={12} lg={12} md={12} sm={12}>
        <div
          className="pt-8 rounded-top-md"
          style={{
            background: `url(${ProfileBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {" "}
        </div>
        <Card className="px-4 pt-2 pb-4 rounded-0 rounded-bottom shadow-sm">
          <div className="d-flex align-items-end justify-content-between  ">
            <div className="d-flex align-items-center">
              <div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                {user?.img != defaultImg && (
                  <Image
                    src={user?.img}
                    className="avatar-xl rounded-circle border border-4 border-white position-relative"
                    alt=""
                  />
                )}
                {user?.img == defaultImg && (
                  <Avatar
                    size={65}
                    name={user?.username}
                    variant="beam"
                    colors={[
                      "#92A1C6",
                      "#146A7C",
                      "#F0AB3D",
                      "#C271B4",
                      "#C20D90",
                    ]}
                  />
                )}

                <Link
                  to="#"
                  className="position-absolute top-0 end-0"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Verified"
                >
                  <Image src={CheckedMark} alt="" height="30" width="30" />
                </Link>
              </div>
              <div className="lh-1">
                <h2 className="mb-0">
                  {user?.username}{" "}
                  {/* <LevelIconWithTooltip level={dashboardData.level} />{" "} */}
                </h2>
                <p className="mb-0 d-block">{user?.email}</p>
              </div>
            </div>
            {/* <div>
              <Link
                to={dashboardData.link}
                className={`btn btn${
                  dashboardData.outlinebutton ? "-outline" : ""
                }-primary btn-sm d-none d-md-block`}
              >
                {dashboardData.linkname}
              </Link>
            </div> */}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ProfileCover;
