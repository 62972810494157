// import node module libraries
import { Fragment } from "react";
import { Col, Row, Card, Container, Button } from "react-bootstrap";
// import custom components
import ProfileCover from "components/marketing/common/headers/ProfileCover";
import { ToastContainer } from "react-toastify";
// import media files
import Avatar3 from "assets/images/avatar/avatar-3.jpg";

// import data files
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
//translate component
import { useTranslation } from "react-i18next";
import BookCard from "../pages/courses/BookCard";
import {
  fetchAllPackDetailsAction,
  fetchBooksInPackAction,
} from "actions/packs";

const PackBook = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { books } = useSelector((state) => state.packs);

  useEffect(() => {
    if (id) dispatch(fetchAllPackDetailsAction(id));
    dispatch(fetchBooksInPackAction(id));
  }, [dispatch, id]);

  const dashboardData = {
    avatar: Avatar3,
    name: "Stella Flores",
    username: "@stellaflores",
    linkname: "Account Setting",
    link: "/student/student-edit-profile",
    verified: false,
    outlinebutton: false,
    level: "Beginner",
  };

  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />
          <Row className="mt-3">
            <Col className="text-end mt-2">
              <Link to="/student/dashboard/">
                <Button>
                  <i className="fe fe-arrow-left me-2"></i>
                  {t("back_to_pack")}
                </Button>
              </Link>
            </Col>
          </Row>
          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={12} md={12} sm={12}>
              <Row className="mb-6">
                <Col md={12}>
                  <Card
                    className="bg-transparent shadow-none "
                    style={{ marginTop: "10px" }}
                  >
                    <Card.Body className="p-0">
                      {/* bookmarked started */}
                      <Row>
                        {books?.map((item, index) => (
                          <Col
                            lg={3}
                            md={6}
                            sm={12}
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/student/book-details/${item.id}?pack_id=${id}`
                              )
                            }
                          >
                            {/* <CourseCard item={item} /> */}
                            <BookCard item={item} />
                          </Col>
                        ))}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};
export default PackBook;
