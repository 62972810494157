import axios from "axios";

import { generateCloudinarySignature } from "helper/utils";
axios.defaults.withCredentials = true;

// const API = axios.create({ baseURL: "http://localhost:5000" });

const API = axios.create({ baseURL: "https://api.iread.education" });
const API_HELP = axios.create({
  baseURL: "https://api-help-center.iread.tn",
  withCredentials: false,
});
const NOTIFICATION_API = axios.create({
  baseURL: "https://notification.iread.tn",
  withCredentials: false,
});

export const NOTIFICATION_KEY = "657041384453ef425e2e426f";
const APIQuiz = axios.create({
  baseURL: "https://quiz.iread.tn/",
  // baseURL: "http://localhost:5044",
  withCredentials: false,
});
const INVOICE_API = axios.create({
  baseURL: "https://invoicing-api.iread.tn",
  withCredentials: false,
});

const SPRINTUP_API = axios.create({
  baseURL: "https://api-sprintup.intellect.tn",
  withCredentials: false,
});
const KEY_INVOICE = "65ba69fb713e132120743444";
const KEY_QUIZ = "65800f77a2ce2e2c88ebd8bd";

const CLOUD_NAME = "dtwkikmuy";
const API_KEY = "564314396825113";
const API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
const cloudinaryAPI = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/destroy`;
const TIMESTAMP = Math.round(new Date().getTime() / 1000);

//user
export const signIn = (formData) => API.post("/reader/login_client", formData);
export const signUp = (formData) => API.post("/reader/register", formData);
export const signInGoogle = (formData) =>
  API.post("/reader/google-login", formData);
export const logOut = () => API.get("/reader/logout");
export const current = () => API.get("/reader/user_authenticated");
export const selectAccount = (formData) =>
  API.post("/reader/select_account", formData);
export const createAccount = (formData) =>
  API.post("/reader/create_account", formData);
export const getAccounts = () => API.get("/reader/get_accounts");
export const readerDashboard = () => API.get("/reader/dashboard");
export const readerUnfollowedBooks = () =>
  API.get("/reader/get_unfollowed_books");
export const getFollowedPackList = () =>
  API.get("/reader/get_followed_pack_list");
export const resendLink = (email) =>
  API.post("/reader/resend_email_confirmation_link", { email });
export const deleteImage = async (publicId) => {
  const signatureParams = {
    public_id: publicId,
    timestamp: TIMESTAMP,
    api_key: API_KEY,
  };
  const signature = generateCloudinarySignature(signatureParams);
  try {
    console.log({
      public_id: "v1691521278/x9gmc0bdxupp647hhmrf",
      signature: signature,
      api_key: API_KEY,
      timestamp: TIMESTAMP,
    });
    return await axios.post(cloudinaryAPI, {
      public_id: publicId,
      signature: signature,
      api_key: API_KEY,
      timestamp: TIMESTAMP,
    });
  } catch (error) {
    console.log(error);
  }
};

export const changeEmail = (formData) =>
  API.post("/reader/set_email", formData);

export const changePassword = (formData) =>
  API.post("/reader/set_password", formData);

export const changeImage = (formData) =>
  API.post("/reader/set_image", formData);

export const getProfile = () => API.get("/reader/my__profile");
export const setProfile = (formData) =>
  API.post("/reader/set_profile", formData);

export const followPack = (id, code) =>
  API.post("/reader/follow_pack", { id, code });
//packs
export const getAllPacks = () => API.get("/main/show_all_pack");
export const getAllPackDetails = (id) =>
  API.post("/main/get_pack_details", { id });
export const getAllBooksInPack = (id) =>
  API.post("/main/get_books_from_pack", { id });

//Books

export const getOneBook = ({ id, pack_id }) =>
  API.post("/main/search_books", { id, pack_id });
export const getBook = (id) => API.get("/admin/get_book/" + id);
export const getBookText = (id) => API.get("/admin/book_text/" + id);
//sesions
export const getSessionById = (id) => API.get("/admin/sessions/" + id);
export const registerSession = (id) =>
  API.post("/reader/register_session", { id });

//contact
export const sendMessage = (formData) => API.post("/main/send_email", formData);

//Sitting Token
export const getSittingToken = async (user_id, token) => {
  const response = await API.post("/reader/start_quiz", { user_id, token });
  return response.data["sitting-token"];
};

// QUIZ API
// export const getAllQuizs = (formData) => API.get("/reader/get_all_quizs", formData);
export const getAllQuizs = (user_id) => {
  return API.post("/reader/assigned", { user_id });
};
export const fetchAllResultsFromOneReader = (user_id) =>
  APIQuiz.get("/userAnswer/" + user_id);
export const startQuiz = (quiz_id, user_id) => {
  return APIQuiz.post("/userAnswer", { quiz_id, user_id });
};
export const getQuiz = (quiz_id) => {
  return APIQuiz.get("/quiz/get_one/" + quiz_id);
};
export const fetchUserDetails = (user_id) => {
  return APIQuiz.get("/user/" + user_id);
};
export const getQuizAnswer = (data) =>
  APIQuiz.post("/userAnswer/get_one", data);
export const firstQuestion = (token, user_id) => {
  return API.post("/reader/first_question", { token, user_id });
};

export const submitAnswer = async (formData) => {
  return APIQuiz.post("/userAnswer/answer", formData);
};

export const getResult = (token, user_id) => {
  return API.post("/reader/result", { token, user_id });
};

export const getQuizsInSession = (formData) =>
  API.post("/admin/get_quiz_in_session", formData);
export const getQuizByToken = (formData) =>
  API.post("/reader/quiz_by_token", formData);

export const getQuestionByCat = (category) =>
  APIQuiz.post("/question/by_cat", category);

export const createQuiz = (fromData) =>
  APIQuiz.post("/quiz/create/" + KEY_QUIZ, fromData);

// logs

export const getCokies = () => API.get("/reader/get_cokies");

// about book

export const getAboutBook = (id) => API.get("/admin/get_about_book/" + id);

//guide from help center
export const fetchAllGuide = () => API_HELP.get("/guide/guidesWithCategories");

//notification

export const fetchOneNotification = (id) =>
  NOTIFICATION_API.get("/notification/get_one/" + id);
export const fetchUserNotification = (id) =>
  API.post("/admin/get_notification", { user_id: id });
export const updateNotification = (noti, id) =>
  NOTIFICATION_API.put("/notification/" + id, noti);
export const fetchNotifications = () =>
  NOTIFICATION_API.get(
    "/notification/general_notification?page=1&app=" + NOTIFICATION_KEY
  );

export const fetchNotificationsByCat = (cat) =>
  NOTIFICATION_API.get(
    "/notification/notification_by_cat?page=1&app=" +
      NOTIFICATION_KEY +
      "&cat=" +
      cat
  );

//Invoice
export const getInvoiceByClient = (id) =>
  INVOICE_API.post("/invoice/get-by-clientId", { clientId: id });
export const getInvoice = (id) => INVOICE_API.get("/invoice/" + id);
export const getInvoiceNumber = () =>
  INVOICE_API.get("/invoice/count?app=" + KEY_INVOICE);

export const createInvoice = (formData) =>
  INVOICE_API.post("/invoice/create", formData);
export const createInvoiceClient = (fromData) =>
  INVOICE_API.post("/", fromData);
export const downloadInvoice = (fromData) =>
  INVOICE_API.post("/pdf/create/", fromData);
export const getPdf = () => INVOICE_API.get("/pdf/fetch-pdf");
export const createInvoiceForClient = () =>
  API.get("/reader/create_invoice_client");

//shcool
export const fetchOneShcool = (id) => API.get("/admin/get_one_shcool/" + id);
export const fetchallShcools = () => API.get("/admin/get_all_shools/");
export const updateShcool = (shcool, id) =>
  API.put("/admin/update_shcool/" + id, shcool);
export const createShcool = (shcool) =>
  API.post("/admin/create_shcool", shcool);
export const deleteShcool = (id) => API.delete("/admin/delete_shcool/" + id);

export const getPacksByShcool = (school) =>
  API.get("reader/get_packs_by_school?school=" + school + "&all=0");
// SPRINTUP_API
export const getJwtToken = (meeting) =>
  SPRINTUP_API.post("jisti-token", meeting);
