// import node module libraries
import { submitAnswerAction } from "actions/quizs";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//translate component
import { useTranslation } from "react-i18next";
import { RESET_ANSWER } from "actions/constants";
import { AlertTriangle, Edit } from "react-feather";
const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  selectedAnswer,
  currentQuestionId,
  setSelectedAnswer,
  withAnswer,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const book_id = searchParams.get("book_id");
  const quizId = searchParams.get("quiz_id");
  const user_id = searchParams.get("user_id");
  const { quiz } = useSelector((state) => state.quizs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const linkToResult = `/quiz/quiz-finish?session_id=${session_id}&book_id=${book_id}&quiz_id=${quizId}&user_id=${user_id}`;
  const { loading } = useSelector((state) => state.auth);
  const { correctAnswer, inCorrectAnswer, qAnswer } = useSelector(
    (state) => state.quizs
  );

  // const user_id = user.quiz_id;
  const [showPopUp, setShowPopUp] = useState(false);

  const handleSubmitQuiz = async () => {
    const requestBody = {
      answer: selectedAnswer,
      question_id: currentQuestionId,
      user_id: user_id,
      quiz_id: quizId,
      last: currentPage === nPages ? true : false,
    };

    try {
      dispatch(
        submitAnswerAction({
          requestBody,
          setSelectedAnswer,
          nextPage,
          navigate,
          withAnswer,
        })
      );
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };
  const handleFinishQuiz = async () => {
    const requestBody = {
      answer: selectedAnswer,
      question_id: currentQuestionId,
      user_id: user_id,
      quiz_id: quizId,
      last: true,
    };

    try {
      dispatch(
        submitAnswerAction({
          requestBody,
          setSelectedAnswer,
          linkToResult,
          navigate,
          setShowPopUp,
        })
      );
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const handleClosePopUp = () => {
    setShowPopUp(false);
    navigate("/instructor/quiz/" + book_id + "?session_id=" + session_id);
  };

  return (
    <div>
      <h5>
        <span style={{ color: "red" }}>{t("notice")}</span> {t("if_you_dont")}
      </h5>

      {!withAnswer && !correctAnswer && !inCorrectAnswer && (
        <div
          className={`d-flex justify-content-${
            currentPage > 1 ? "between" : "end"
          }`}
          style={{ marginTop: "20px" }}
        >
          {currentPage > 1 && (
            <Button variant="secondary" onClick={prevPage}>
              <i className="fe fe-arrow-left"></i> {t("previous")}
            </Button>
          )}
          {currentPage === nPages ? (
            <Link
              className="btn btn-primary"
              onClick={() => handleFinishQuiz()}
            >
              {t("finish")}
            </Link>
          ) : (
            <Button
              variant="primary"
              disabled={loading}
              onClick={() => handleSubmitQuiz()}
            >
              {t("next")} <i className="fe fe-arrow-right"></i>
            </Button>
          )}
        </div>
      )}
      {withAnswer && !correctAnswer && !inCorrectAnswer && (
        <div
          className={`d-flex justify-content-${
            currentPage > 1 ? "between" : "end"
          }`}
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => handleSubmitQuiz()}
          >
            {t("confirm")}
          </Button>
        </div>
      )}
      {correctAnswer && withAnswer && (
        <div
          style={{
            backgroundColor: "rgba(0, 255, 0, 0.1)",
            border: "1px solid green",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <i
            className="feather-icon check-circle"
            style={{ color: "green", fontSize: "24px" }}
          ></i>
          <p style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}>
            Excellent!
          </p>
          <Button
            variant="success"
            onClick={() => {
              dispatch({ type: RESET_ANSWER });
              if (currentPage === nPages && !quiz.training) {
                navigate(linkToResult);
              } else if (currentPage === nPages && quiz.training) {
                navigate(`/quiz/training/${book_id}`);
              } else {
                nextPage();
              }
            }}
          >
            {currentPage == nPages ? t("finish") : t("next")}
          </Button>
        </div>
      )}

      {inCorrectAnswer && withAnswer && (
        <div
          style={{
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            border: "1px solid red",
            borderRadius: "8px",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px", // Adds spacing between flex items
          }}
        >
          {/* Icon Section */}
          <i
            className="feather-icon x-circle"
            style={{ color: "red", fontSize: "24px" }}
            aria-label="Error icon"
          ></i>

          {/* Alert Triangle Section */}

          <AlertTriangle style={{ color: "red" }} size={40} />

          {/* Text Section */}
          <div
            style={{
              color: "red",
              flex: 1, // Allows the text to expand and take available space
              marginLeft: "8px", // Adds spacing between text and icons
            }}
            dangerouslySetInnerHTML={{ __html: qAnswer }}
          />

          {/* Button Section */}
          <Button
            variant="danger"
            onClick={() => {
              dispatch({ type: RESET_ANSWER });
              if (currentPage === nPages && !quiz.training) {
                navigate(linkToResult);
              } else if (currentPage === nPages && quiz.training) {
                navigate(`/quiz/training/${book_id}`);
              } else {
                nextPage();
              }
            }}
            style={{
              backgroundColor: "#d9534f", // Bootstrap danger button color
              border: "none",
              borderRadius: "4px",
              padding: "8px 16px",
              fontWeight: "bold",
            }}
          >
            {currentPage === nPages ? t("finish") : t("next")}
          </Button>
        </div>
      )}

      <Modal show={showPopUp} onHide={() => setShowPopUp(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Time's Up!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-secondary">
            Sorry, your time for this quiz has expired.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopUp}>
            Return to Quiz List
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pagination;
