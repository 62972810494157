// import node module libraries

import { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Image, Dropdown, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// simple bar scrolling used for notification item scrolling
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useSelector, useDispatch } from "react-redux";
import { logOutAction } from "actions/auth";
import {
  fetchUserNotificationAction,
  newNotificationAction,
  updateNotificationAction,
} from "actions/notification";
import { getTimeValue } from "helper/utils";
import { NOTIFICATION_KEY } from "api";
import { io } from "socket.io-client";
import sound from "../assets/audio/notification.wav";
import Avatar from "boring-avatars";

const socket = io("https://notification.iread.tn");
//translate component
import { useTranslation } from "react-i18next";

const QuickMenu = () => {
  //
  const defaultImg =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png";
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("selectedLanguage"));
  }, [selectedLanguage]);
  const helpCenterUrl = `https://help-center.iread.tn/help-center?lng=${selectedLanguage}`;
  const playNotificationSound = () => {
    // Create a new Audio object
    const audio = new Audio(sound);
    audio.muted = false;
    // Play the notification sound
    audio.play().catch((error) => {
      // Handle the error (e.g., display a message to the user)
      console.error("Error playing notification sound:", error);
    });
  };

  // Assume this function is called in response to a user action
  const handleUserInteraction = () => {
    playNotificationSound();
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [ping, setPing] = useState(false);

  const { user, notification, followed_pack_list, followed_sessions } =
    useSelector((state) => state.auth) || {
      user: {},
      notification: [],
      followed_pack_list: [],
    };

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  // const handlePushNotification = (notification) => {
  //   const payload = {
  //     title: notification.title,
  //     options: {
  //       body: notification.desc,
  //       data: {
  //         link: notification.link,
  //       },
  //     },
  //   };

  //   navigator.serviceWorker.ready.then((registration) => {
  //     registration.showNotification(payload.title, payload.options);
  //   });
  // };
  useEffect(() => {
    const handleNotification = (noti) => {
      dispatch(newNotificationAction(noti));
      // handlePushNotification(noti);
      handleUserInteraction();
    };

    socket
      .off(
        "Pack" + followed_pack_list[0]?.id + NOTIFICATION_KEY,
        handleNotification
      )
      .off();
    socket.off("AllAll" + NOTIFICATION_KEY, handleNotification).off();
    socket.off("User" + user?.id + NOTIFICATION_KEY, handleNotification).off();

    followed_sessions?.forEach((session) => {
      socket
        .off("Session" + session.id + NOTIFICATION_KEY, handleNotification)
        .off();
    });
    // Add new event listener
    if (followed_sessions && followed_sessions.length > 0) {
      followed_sessions?.forEach((session) => {
        socket.on(
          "Session" + session.id + NOTIFICATION_KEY,
          handleNotification
        );
      });
    }
    if (followed_pack_list && followed_pack_list?.length > 0) {
      socket.on(
        "Pack" + followed_pack_list[0]?.id + NOTIFICATION_KEY,
        handleNotification
      );
    }

    socket.on("AllAll" + NOTIFICATION_KEY, handleNotification);
    socket.on("User" + user?.id + NOTIFICATION_KEY, handleNotification);

    return () => {
      // Disconnect and remove the event listener when the component is unmounted
      socket.off("AllAll" + NOTIFICATION_KEY, handleNotification).off();
      socket
        .off("User" + user?.id + NOTIFICATION_KEY, handleNotification)
        .off();

      if (followed_pack_list.length > 0) {
        socket
          .off(
            "Pack" + followed_pack_list[0]?.id + NOTIFICATION_KEY,
            handleNotification
          )
          .off();
      }

      socket.disconnect();
    };
  }, []);

  const handleLogOut = (e) => {
    e.preventDefault();
    dispatch(logOutAction());
  };

  const handleReadNotification = (id) => {
    dispatch(updateNotificationAction({ isRead: true }, id));
  };
  useEffect(() => {
    if (user?.id) {
      if (followed_pack_list && followed_pack_list?.length > 0) {
        dispatch(
          fetchUserNotificationAction(user?.id, followed_pack_list[0].id)
        );
      } else {
        dispatch(fetchUserNotificationAction(user?.id));
      }
    }
  }, [dispatch]);

  const Notifications = () => {
    return (
      <SimpleBar style={{ maxHeight: "300px" }}>
        <ListGroup variant="flush">
          {notification?.length > 0 ? (
            notification?.map(function (item, index) {
              return (
                <ListGroup.Item
                  className={item?.isRead ? "bg-light" : ""}
                  key={index}
                >
                  <Row>
                    <Col>
                      <Link className="text-body" to={item?.link}>
                        <div
                          className="d-flex"
                          onClick={() => {
                            if (item?.cat?.title == "User")
                              handleReadNotification(item?._id);
                          }}
                        >
                          <Image
                            src={item?.cat?.img}
                            alt="img"
                            className="avatar-md rounded-circle"
                          />
                          <div className="ms-3">
                            <h5 className="fw-bold mb-1">{item?.title}</h5>
                            <p className="mb-3">{item?.desc}</p>
                            <span className="fs-6 text-muted">
                              <span>
                                <span className="fe fe-thumbs-up text-success me-1"></span>
                                {getTimeValue(new Date(item?.createdAt))}
                              </span>
                              {/* <span className="ms-1">{item.time}</span> */}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </Col>
                    {/* <Col className="col-auto text-center me-2">
                    <GKTippy content="Mark as unread">
                      <Link to="#">
                        <DotBadge bg="secondary"></DotBadge>
                      </Link>
                    </GKTippy>
                  </Col> */}
                  </Row>
                </ListGroup.Item>
              );
            })
          ) : (
            <div className="ms-3">
              <p className="mb-3">{t("no_notification")}</p>
            </div>
          )}
        </ListGroup>
      </SimpleBar>
    );
  };
  return (
    <Fragment>
      {/* <DarkLightMode /> */}
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-2 d-flex nav-top-wrap"
      >
        <Dropdown as="li">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            style={{
              backgroundColor: notification?.length > 0 ? "#754FFE" : "initial",
            }}
            className={`text-dark icon-notifications me-lg-1 btn btn-light btn-icon rounded-circle ${
              notification?.length === 0
                ? "indicator indicator-no-notifications text-muted"
                : "indicator indicator-primary"
            }`}
            id="dropdownNotification"
          >
            <i
              className="fe fe-bell"
              style={{
                color: notification?.length > 0 ? "white" : "initial",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-3 py-0"
            aria-labelledby="dropdownNotification"
            align="end"
          >
            <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
              <span className="h4 mb-0">{t("notifications")}</span>
              <Link to="# " className="text-muted">
                <span className="align-middle">
                  <i className="fe fe-settings me-1"></i>
                </span>
              </Link>
            </div>
            <Notifications />
            {/* <div className="border-top px-3 pt-3 pb-3">
              <Link
                to="/authentication/notifications"
                className="text-link fw-semi-bold"
              >
                See all Notifications
              </Link>
            </div> */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as="li" className="ms-1">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              {user?.img != defaultImg ? (
                <Image
                  alt="avatar"
                  src={user?.img}
                  className="rounded-circle"
                />
              ) : (
                <Avatar
                  size={40}
                  name={user?.username}
                  variant="beam"
                  colors={[
                    "#92A1C6",
                    "#146A7C",
                    "#F0AB3D",
                    "#C271B4",
                    "#C20D90",
                  ]}
                />
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownUser"
            align="end"
          >
            <Dropdown.Item className="mt-3">
              <div className="d-flex">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <Image
                    alt="avatar"
                    src={user?.img}
                    className="rounded-circle"
                  />
                </div>
                <div className="ms-3 lh-1">
                  <h5 className="mb-1">{user?.username}</h5>
                  <p className="mb-0 text-muted">{user?.email}</p>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey="8"
              onClick={() => navigate("/reader/dashboard")}
            >
              <i className="fe fe-user me-2"></i> {t("my_dashbor")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              onClick={() => navigate("/student/dashboard")}
            >
              <i className="fe fe-star me-2"></i> {t("my_pack")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => navigate("/student/student-books/")}
            >
              <i className="fe fe-book me-2"></i> {t("my_liberary")}
            </Dropdown.Item>
            {/* <Dropdown.Item
              eventKey="3"
              onClick={() => navigate("/student/invoice/")}
            >
              <i className="fe fe-dollar-sign me-2"></i> {t("my_invoices")}
            </Dropdown.Item> */}
            <Dropdown.Item
              eventKey="4"
              onClick={() => navigate("/student/student-edit-profile")}
            >
              <i className="fe fe-settings me-2"></i>
              {t("account_setting")}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="5"
              onClick={() => (window.location.href = helpCenterUrl)}
            >
              <i className="fe fe-help-circle me-2"></i> {t("help_center")}
            </Dropdown.Item>
            {/* <Dropdown.Item
              onClick={() =>
                navigate("/student/student-edit-profile")
              }
            >
              <i className="fe fe-settings me-2"></i> Settings
            </Dropdown.Item> */}
            <Dropdown.Divider />
            <Dropdown.Item className="mb-3" onClick={(e) => handleLogOut(e)}>
              <i className="fe fe-power me-2"></i> {t("sign_out")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    </Fragment>
  );
};

export default QuickMenu;
