export const DashboardMenu = [
  {
    id: 1,
    title: "My Packs",
    link: "/student/dashboard",
    icon: "home",
  },
  {
    id: 2,
    title: "My Books",
    link: "/student/student-books/",
    icon: "book",
  },
];

export const AccountSettingsMenu = [
  {
    id: 1,
    title: "Sessions",
    link: "/student/book-details/",
    icon: "book",
  },
  {
    id: 3,
    title: "Games",
    link: "/student/games/",
    icon: "gamepad",
  },
  {
    id: 3,
    title: "Training",
    link: "/quiz/training/",
    icon: "bell",
  },
];

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
