import React, { Fragment, useState, useEffect } from "react";

const Matching = ({ setSelectedAnswer, item, disabled }) => {
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [selectedMatches, setSelectedMatches] = useState({});
  const [matchColors, setMatchColors] = useState({});
  const [shuffledLeftData, setShuffledLeftData] = useState([]);
  const [unusedColors, setUnusedColors] = useState([]);

  const colors = [
    "rgba(255, 255, 0, 0.6)", // Yellow
    "rgba(102, 179, 255, 0.6)", // Light Blue
    "rgba(168, 230, 161, 0.6)", // Green
    "rgba(255, 128, 171, 0.6)", // Pink
    "rgba(255, 184, 77, 0.6)", // Orange
    "rgba(194, 160, 255, 0.6)", // Purple
  ];

  // Function to get a random color from unused colors
  const getRandomColor = () => {
    // Reset the unused colors array when all colors are used
    if (unusedColors.length === 0) {
      setUnusedColors([...colors]);
      return colors[Math.floor(Math.random() * colors.length)];
    }

    // Pick a random color from the unused colors
    const randomIndex = Math.floor(Math.random() * unusedColors.length);
    const color = unusedColors[randomIndex];

    // Remove the selected color from unused colors
    setUnusedColors(unusedColors.filter((_, index) => index !== randomIndex));

    return color;
  };

  // Shuffle function to randomize array order
  const shuffleArray = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  useEffect(() => {
    // Shuffle only the left data when the component mounts or when the item changes
    const shuffledLeft = shuffleArray(item.data.map((entry) => entry.left));
    setShuffledLeftData(shuffledLeft);

    // Initialize unused colors
    setUnusedColors([...colors]);
  }, [item]);

  // Handle left item selection (for matching)
  const handleLeftSelect = (leftIndex) => {
    if (disabled) return; // Prevent interaction if disabled

    setSelectedLeft(leftIndex);
    if (!matchColors[leftIndex]) {
      const color = getRandomColor();
      setMatchColors({ ...matchColors, [leftIndex]: color });
    }
  };

  // Handle right item selection (for matching)
  const handleRightSelect = (leftIndex, rightValue) => {
    if (disabled) return; // Prevent interaction if disabled

    if (selectedLeft !== null) {
      const newMatches = {
        ...selectedMatches,
        [leftIndex]: rightValue,
      };
      setSelectedMatches(newMatches);

      // Update selected answers in the desired format
      const updatedAnswers = Object.keys(newMatches).map((index) => {
        const leftItem = shuffledLeftData[parseInt(index, 10)];
        return { left: leftItem, right: newMatches[index] };
      });

      setSelectedAnswer(updatedAnswers);

      // Reset selectedLeft to allow new selections
      setSelectedLeft(null);
    }
  };

  return (
    <Fragment>
      <div className="row">
        {/* Left Column: Items to match */}
        <div className="col">
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {shuffledLeftData.map((leftValue, index) => (
              <li
                key={index}
                onClick={() => handleLeftSelect(index)}
                style={{
                  backgroundColor:
                    selectedLeft === index || selectedMatches[index]
                      ? matchColors[index]
                      : disabled
                      ? "#f8d7da"
                      : "",
                  cursor: disabled ? "not-allowed" : "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                  opacity: disabled ? 0.6 : 1,
                }}
              >
                {leftValue}
              </li>
            ))}
          </ul>
        </div>

        {/* Right Column: Selectable matches */}
        <div className="col">
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {item.data.map((answer, index) => (
              <li
                key={index}
                onClick={() =>
                  !disabled &&
                  selectedLeft !== null &&
                  handleRightSelect(selectedLeft, answer.right)
                }
                style={{
                  backgroundColor: Object.values(selectedMatches).includes(
                    answer.right
                  )
                    ? matchColors[
                        Object.keys(selectedMatches).find(
                          (key) => selectedMatches[key] === answer.right
                        )
                      ]
                    : disabled
                    ? "#f8d7da"
                    : "",
                  cursor: disabled ? "not-allowed" : "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                  opacity: disabled ? 0.6 : 1,
                }}
              >
                {answer.right}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Matching;
