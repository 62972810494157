// import node module libraries
import React, { useState } from "react";
import { Card, Table, Badge, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

// import data files

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
import { useSelector } from "react-redux";
import { reformatSearchDateTime } from "helper/utils";
import { useDispatch } from "react-redux";
import { followSession } from "actions/sessions";

const Invoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { book } = useSelector((state) => state.books);
  const { followed_sessions, loading } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
    publicId: null,
  });
  const sessionInBook = followed_sessions?.filter(
    (session) => session.book_id == id
  );
  const availebleSessions = book.sessions
    ?.filter((session) => {
      return !sessionInBook.some(
        (s) => s.id === session.id || s.unit_id === session.unit_id
      );
    })
    ?.map((s) => {
      return {
        id: s.id,
        unit_id: s.unit_id,
        session_name: s.title,
        book_id: id,
        date: reformatSearchDateTime(s.start),

        approved: false,
        location: s.extendedProps.location,
      };
    })
    ?.concat(sessionInBook);
  const handleSession = (session) => {
    if (session.approved) {
      navigate(
        "/instructor/dashboard/" + session.book_id + "?session_id=" + session.id
      );
    } else {
      setSelectedEvent({
        publicId: session.id,
      });
      setShow(true);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleConfirm = () => {
    dispatch(followSession(selectedEvent, handleClose));
  };
  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">Sessions</h3>
            <p className="mb-0">You can find all of your story session here.</p>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {/* Table */}
          <div className="table-invoice border-0">
            <Table
              hover
              responsive
              className="mb-0 text-nowrap table-centered "
            >
              <thead className="table-light">
                <tr>
                  <th scope="col">Session</th>
                  <th scope="col">Date</th>
                  <th scope="col">location</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {availebleSessions?.map((item, index) => (
                  <tr
                    className="cursor-pointer"
                    key={index}
                    onClick={() => handleSession(item)}
                  >
                    <td>{item.session_name}</td>
                    <td>{item.date}</td>
                    <td>{item.location}</td>
                    <td>
                      <Badge bg={!item.approved ? "primary" : "success"}>
                        {item.approved ? "Aspproved" : "follow"}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to follow this session?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </ProfileLayout>
  );
};

export default Invoice;
