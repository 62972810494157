// import node module libraries
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Image,
  Spinner,
  Table,
  OverlayTrigger,
  Tooltip,
  Accordion,
  ListGroup,
  useAccordionButton,
} from "react-bootstrap";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";

// import profile layout wrapper
import ProfileLayout from "components/marketing/student/ProfileLayout";
import { useSelector } from "react-redux";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeEmailAction,
  changeImageActions,
  changePasswordAction,
  createAccountAction,
  getAccountsAction,
  getProfileActions,
  selectAccountAction,
  setProfileActions,
} from "actions/auth";
import PasswordStrengthMeter from "components/elements/passwordstrength/PasswordStrengthMeter";
import { toast, ToastContainer } from "react-toastify";
import SubsLayout from "../student/SubsLayout";
//translate component
import { useTranslation } from "react-i18next";
import { Avatar as Avatar1 } from "components/elements/bootstrap/Avatar";
import { cefrData } from "data/cefr";
import CefrCard from "../common/cards/CefrCard";
import schoolImage from "assets/images/png/school.png";
import Avatar from "boring-avatars";
const EditProfile = () => {
  const defaultImg =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    loading,
    profile: userProfile,
    accounts,
  } = useSelector((state) => state.auth);
  const [image, setImage] = useState(user?.img);
  const [newAccount, setNewAccont] = useState({
    username: "",
    password: "",
  });
  const [profile, setProfile] = useState({
    address_1: "",
    address_2: "",
    birth_day: "",
    country: "",
    first_name: "",
    last_name: "",
    phone: "",
    state: "",
  });
  useEffect(() => {
    if (user) {
      dispatch(getAccountsAction());
    }
  }, [dispatch, user]);

  const handleCreateAccount = (e) => {
    e.preventDefault();
    dispatch(
      createAccountAction(
        { ...newAccount, school_id: localStorage.getItem("school_id") },
        setNewAccont
      )
    );
  };
  const pathInfo = useLocation();
  const statelist = [
    { value: "Gabes", label: "Gabes" },
    { value: "Sfax", label: "Sfax" },
    { value: "Tunis", label: "Tunis" },
  ];

  const handleSlecetAccount = (formdata) => {
    dispatch(selectAccountAction(formdata));
  };
  const countrylist = [
    { value: "empty", label: "Select country" },
    { value: "Tunisia", label: "Tunisia" },
    // { value: "Algeria", label: "Algeria" },
    // { value: "Morocco", label: "Morocco" },
    // { value: "France", label: "France" },
    // { value: "UK", label: "UK" },
    // { value: "USA", label: "USA" },
  ];
  useEffect(() => {
    if (user) {
      setImage(user.img);
      dispatch(getProfileActions());
    }
  }, [user]);

  useEffect(() => {
    if (userProfile?.user_id)
      setProfile({
        address_1: userProfile.address_1,
        address_2: userProfile.address_2,
        birth_day: userProfile.birth_day,
        country: userProfile.country,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        phone: userProfile.phone,
        state: userProfile.state,
      });
  }, [userProfile]);

  const handleChangeProfile = (e) => {
    e.preventDefault();
    const newDate = new Date(profile.birth_day);
    newDate.setDate(newDate.getDate() + 1);
    dispatch(setProfileActions({ ...profile, birth_day: newDate }));
    dispatch(changeImageActions({ img: image }));
  };

  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [currentpassword, setCurrentPassword] = useState("");
  const [currentpassword2, setCurrentPassword2] = useState("");
  const [new_email, setNew_email] = useState();

  const handleChangeEmail = (e) => {
    e.preventDefault();
    dispatch(changeEmailAction({ new_email, password: currentpassword })).then(
      () => {
        setNew_email("");
        setCurrentPassword("");
      }
    );
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      dispatch(
        changePasswordAction({
          old_password: currentpassword2,
          new_password: password,
        })
      ).then(() => {
        setCurrentPassword2("");
        setPassword("");
        setConfirmPassword("");
      });
    } else {
      toast.error("Password doesn't match", { autoClose: 1000 });
    }
  };
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    return (
      <Fragment>
        <Link
          to="#"
          onClick={decoratedOnClick}
          className="d-flex align-items-center text-inherit text-decoration-none  mb-0"
          data-bs-toggle="collapse"
          style={{ marginTop: "1%" }}
          aria-controls={`collapse-${eventKey}`} // Use unique IDs for aria-controls
        >
          <Col style={{ position: "relative" }}>
            <Card
              style={{
                padding: "3% 0",
                borderLeft: `10px solid ${children.color}`,
                borderRadius: "8px  8px 0 0",
              }}
            >
              <Card.Body>
                <div className="mb-3 mb-lg-0">
                  <h3 className="mb-0">{children.title}</h3>
                  <p className="mb-0">{children.desc}</p>
                </div>
              </Card.Body>
            </Card>
            <span
              className="chevron-arrow ms-5 "
              style={{ position: "absolute", right: "50%", bottom: 5 }}
            >
              <i
                className="fe fe-chevron-down "
                style={{ fontSize: "25px ", color: `${children.color}` }}
              ></i>
            </span>
          </Col>
        </Link>
      </Fragment>
    );
  };
  return (
    <SubsLayout>
      <Accordion>
        <ListGroup as="ul" variant="flush">
          <ContextAwareToggle eventKey="1">
            {{
              title: t("profile_details"),
              desc: t("you_have_full"),
              color: "#0EA5E9",
            }}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="1">
            <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
              <Card.Body
                style={{
                  borderLeft: "10px solid #0EA5E9",
                  borderRadius: "0 0 0 8px",
                }}
              >
                <div className="d-lg-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center mb-4 mb-lg-0">
                    <Image
                      src={image}
                      id="img-uploaded"
                      className="avatar-xl rounded-circle"
                      alt=""
                    />
                    <div className="ms-3">
                      <h4 className="mb-0">{t("your_avatar")}</h4>
                      <p className="mb-0">PNG or JPG {t("no_bigger_than")}</p>
                    </div>
                  </div>

                  <CloudinaryUploadWidget setImage={setImage} />
                  {/* <Button variant="outline-secondary" size="sm">
                Update
              </Button>{" "} */}
                  {/* <Button variant="outline-danger" size="sm">
                Delete
              </Button> */}
                </div>
                <hr className="my-5" />
                <div>
                  <h4 className="mb-0">{t("personal_details")}</h4>
                  <p className="mb-4">{t("edit_your_personal")}</p>
                  {/* Form */}
                  <Form>
                    <Row>
                      {/* First name */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formFirstName">
                          <Form.Label>{t("first_name")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("first_name")}
                            required
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                first_name: e.target.value,
                              })
                            }
                            value={profile.first_name}
                          />
                        </Form.Group>
                      </Col>

                      {/* Last name */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formLastName">
                          <Form.Label>{t("last_name")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("last_name")}
                            required
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                last_name: e.target.value,
                              })
                            }
                            value={profile.last_name}
                          />
                        </Form.Group>
                      </Col>

                      {/* Phone */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formPhone">
                          <Form.Label>{t("phone")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("phone")}
                            required
                            onChange={(e) =>
                              setProfile({ ...profile, phone: e.target.value })
                            }
                            value={profile.phone}
                          />
                        </Form.Group>
                      </Col>

                      {/* Birthday */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formBirthday">
                          <Form.Label>{t("birthday")}</Form.Label>
                          <Form.Control
                            as={FlatPickr}
                            value={profile.birth_day}
                            placeholder={t("date_of_birthday")}
                            required
                            onChange={(e) =>
                              setProfile({ ...profile, birth_day: new Date(e) })
                            }
                          />
                        </Form.Group>
                      </Col>

                      {/* Address Line 1 */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formBirthday">
                          <Form.Label>{t("adress_line_1")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("adress_line_1")}
                            required
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                address_1: e.target.value,
                              })
                            }
                            value={profile.address_1}
                          />
                        </Form.Group>
                      </Col>

                      {/* Address Line 2 */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formBirthday">
                          <Form.Label>{t("adress_line_2")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("adress_line_2")}
                            required
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                address_2: e.target.value,
                              })
                            }
                            value={profile.address_2}
                          />
                        </Form.Group>
                      </Col>

                      {/* Country */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formState">
                          <Form.Label>{t("country")}</Form.Label>
                          <FormSelect
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                country: e.target.value,
                              })
                            }
                            defaultselected={profile.country}
                            options={countrylist}
                          />
                        </Form.Group>
                      </Col>
                      {/* State */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formState">
                          <Form.Label>{t("")}State</Form.Label>
                          <FormSelect
                            onChange={(e) =>
                              setProfile({ ...profile, state: e.target.value })
                            }
                            defaultselected={profile.state}
                            options={statelist}
                          />
                        </Form.Group>
                      </Col>
                      {/* Button */}
                      <Col sm={12} md={12}>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={(e) => handleChangeProfile(e)}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              className="me-2"
                              size="sm"
                            />
                          ) : (
                            t("update_profil")
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
          <ContextAwareToggle eventKey="2">
            {{
              title: t("security"),
              desc: t("edit_your_account"),
              color: "#DC2626",
            }}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="2">
            <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
              <Card.Body
                style={{
                  borderLeft: "10px solid #DC2626",
                  borderRadius: "0 0 0 8px",
                }}
              >
                <h4 className="mb-0">{t("email_adress")}</h4>
                <p>
                  {t("your_current_email")}{" "}
                  <span className="text-success">{user?.email}</span>
                </p>
                <Form>
                  <Row>
                    <Col lg={6} md={12} sm={12} className="mb-3">
                      <Form.Group>
                        <Form.Label htmlFor="email">
                          {t("new_email_adress")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          required
                          onChange={(e) => setNew_email(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="currentpassword">
                          {t("current_password")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="currentpassword"
                          value={currentpassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Button
                        type="submit"
                        className="btn btn-primary mt-2"
                        onClick={(e) => handleChangeEmail(e)}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            className="me-2"
                            size="sm"
                          />
                        ) : (
                          t("update_details")
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <hr className="my-5" />
                <div>
                  <h4 className="mb-0">{t("change_password")}</h4>
                  <p>{t("we_will_email")}</p>
                  {/* Form */}
                  <Form>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        {/* Current password */}

                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="currentpassword">
                            {t("current_password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            id="currentpassword"
                            value={currentpassword2}
                            onChange={(e) =>
                              setCurrentPassword2(e.target.value)
                            }
                            required
                          />
                        </Form.Group>

                        {/* New password */}
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="newpassword">
                            {t("new_password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            id="newpassword"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Row className="align-items-center g-0">
                          <Col sm={6}>
                            <span
                              data-bs-toggle="tooltip"
                              data-placement="right"
                              title=""
                            >
                              {t("password_strength")}
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    {t("test_if_by_typing")}
                                  </Tooltip>
                                }
                              >
                                <i className="fas fa-question-circle ms-1"></i>
                              </OverlayTrigger>
                            </span>
                          </Col>
                        </Row>
                        <PasswordStrengthMeter password={password} />

                        {/* Confirm new password */}
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="confirmpassword">
                            {t("confirm_new_password")}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            id="confirmpassword"
                            value={confirmpassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                        {/* Button */}
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => handleChangePassword(e)}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              className="me-2"
                              size="sm"
                            />
                          ) : (
                            t("save_password")
                          )}
                        </Button>
                        <div className="col-6"></div>
                      </Col>
                      <Col lg={12} md={12} sm={12} className="mt-4">
                        <p className="mb-0">
                          {t("cant_remenber_your")}{" "}
                          <Link to="#">{t("reset_your_password")}</Link>
                        </p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Accordion.Collapse>

          <ContextAwareToggle eventKey="3">
            {{
              title: t("accounts"),
              desc: t("accounts_managment"),
              color: "#F8C709",
            }}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="3">
            <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
              <Card.Body
                style={{
                  borderLeft: "10px solid #F8C709",
                  borderRadius: "0 0 0 8px",
                }}
              >
                <Table className="text-nowrap">
                  <tbody>
                    {accounts?.map((account, index) => (
                      <tr
                        onClick={() =>
                          handleSlecetAccount({
                            username: account.username,
                            email: account.email,
                          })
                        }
                        style={{ cursor: "pointer", marginTop: "15px" }}
                        key={index}
                      >
                        <td>
                          {account?.img == defaultImg ? (
                            <Avatar
                              size={40}
                              name={account?.username}
                              variant="beam"
                              colors={[
                                "#92A1C6",
                                "#146A7C",
                                "#F0AB3D",
                                "#C271B4",
                                "#C20D90",
                              ]}
                            />
                          ) : (
                            <Avatar1
                              size="md"
                              type="image"
                              src={account?.img}
                              className="rounded-circle"
                              alt="G K"
                            />
                          )}
                        </td>
                        <th scope="row">{account?.username}</th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {accounts?.length < 3 && (
                  <form>
                    <div className="mb-3">
                      {/* User name */}
                      <label htmlFor="username">{t("user_name")}</label>
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        placeholder={t("user_name")}
                        required
                        onChange={(e) =>
                          setNewAccont({
                            ...newAccount,
                            username: e.target.value,
                          })
                        }
                        value={newAccount.username}
                      />
                    </div>

                    <div className="mb-3">
                      {/* Password */}
                      <label htmlFor="password">{t("password")}</label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder={t("password")}
                        required
                        onChange={(e) =>
                          setNewAccont({
                            ...newAccount,
                            password: e.target.value,
                          })
                        }
                        value={newAccount.password}
                      />
                    </div>

                    {/* Button */}
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => handleCreateAccount(e)}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            className="me-2"
                            size="sm"
                          />
                        ) : (
                          t("add_account")
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Card.Body>
            </Card>
          </Accordion.Collapse>
          <ContextAwareToggle eventKey="4">
            {{ title: t("cefr"), desc: t("cefr_desc"), color: "#2D851D" }}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="4">
            <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
              <Card.Body
                style={{
                  borderLeft: "10px solid #2D851D",
                  borderRadius: "0 0 0 8px",
                }}
              >
                <Row>
                  {cefrData.map((item, index) => (
                    <Col key={index} md={6} sm={12}>
                      <CefrCard item={item} />
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
          <ContextAwareToggle eventKey="5">
            {{
              title: t("schools"),
              desc: t("schools_managment"),
              color: "#F15B05",
            }}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="5">
            <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
              <Card.Body
                style={{
                  borderLeft: "10px solid #F15B05",
                  borderRadius: "0 0 0 8px",
                }}
              >
                <Table className="text-nowrap">
                  <tbody>
                    {user?.schools?.map((school, index) => (
                      <tr
                        style={{ cursor: "pointer", marginTop: "15px" }}
                        key={index}
                        onClick={() => {
                          localStorage.setItem("school_id", school.id);
                          toast.success(`${school.name} has been selected`);
                        }}
                      >
                        <td>
                          <Avatar1
                            size="md"
                            type="image"
                            src={schoolImage}
                            className="rounded-circle"
                            alt="G K"
                          />
                        </td>
                        <th scope="row">{school?.name}</th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </ListGroup>
      </Accordion>
      <ToastContainer />
    </SubsLayout>
  );
};

export default EditProfile;
